import React from "react";

import Topbar from "../Topbar";
import Sidebar from "./Sidebar";

import SettingsContext from "../../context/SettingsContext";

import "./Layout.scss";

const Layout = ({ children, topbarFixed }) => {
  return (
    <SettingsContext.Consumer>
      {(settings) => (
        <div className={`layout ${settings.nightMode ? "nightMode" : ""}`}>
          <Sidebar />

          <div className="main">
            <Topbar topbarFixed={topbarFixed} />

            {/* main -> body */}
            <div className="content">{children}</div>
          </div>
        </div>
      )}
    </SettingsContext.Consumer>
  );
};

export default Layout;
